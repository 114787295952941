import { Navigate, Route, Routes } from 'react-router-dom';

import BronUpdatePage from '../pages/BronUpdatePage';
import MyBronTicketsPage from '../pages/MyBronTicketsPage';
import MyTicketsPage from '../pages/MyTicketsPage';

const PrivateRoutes = () => {
  return (
        <Routes>
            <Route path="/auth/*" element={<Navigate replace to="/" />} />
            <Route path="/my-tickets/*" element={<MyTicketsPage />} />
            <Route path="/my-bron-tickets/*" element={<MyBronTicketsPage />} />
            <Route path="/bron-update/:id" element={<BronUpdatePage />} />
        </Routes>
  );
};

export default PrivateRoutes;
