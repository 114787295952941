import { Fragment, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InfoCircle } from 'components/icons';
import Input from 'components/input/Input';
import RenderIf from 'components/RenderIf';
import { useFormik } from 'formik';
import useOtpTimer from 'hooks/useOtpTimer';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../../components/button/Button';
import { changePassword } from '../_redux/authCrud';
import CustomProgress from '../components/CustomProgress';

export default function CheckRegister() {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const togglePasswordVisibilityTwo = () => {
    setShowPasswordTwo(!showPasswordTwo);
  };
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const { time, isFinished, isDisabled, error: otpError, resendOtp } = useOtpTimer(60);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const { phoneNumber } = useSelector(({ auth }) => ({
    phoneNumber: auth.phoneNumber?.replace(/[()+\s-]/g, ''),
  }));

  const formik = useFormik({
    initialValues: {
      otp: '',
      pin: '',
      pinCheck: '',
    },
    validationSchema: Yup.object({
      otp: Yup.string().required('OTP kodu daxil edin'),
      pin: Yup.string()
        .required('Pin kodu daxil edin')
        .min(8, 'Ən az 8 xarakter olmalıdır')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
          'Pin böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.'
        ),
      pinCheck: Yup.string().oneOf(
        [Yup.ref('pin'), null],
        'Pin kodu təkrar düzgün daxil edin'
      ),
    }),
    onSubmit: (values) => {
      enableLoading();
      changePassword(values.otp, phoneNumber, values.pin)
        .then(() => {
          disableLoading();
          navigate('/auth/login');
        })
        .catch((err) => {
          disableLoading();
          setErrMsg(err.response.data.message);
        });
      formik.resetForm({
        values: { otp: '', pin: '', pinCheck: '' },
      });
    },
  });

  return (
    <Fragment>
      <Helmet>
        <title>Pin-i yenilə</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} className="auth__check-register">
        <Link to="/" className="auth__logo">
          <img src="/media/1x2-logo.svg" alt="Topaz" />
        </Link>
        <div className="auth__title">
          <span>Pin kodu yeniləyin</span>
        </div>
        <p className="auth__text-gray mb-3">
          Zəhmət olmasa tələb olunan məlumatları daxil edin
        </p>
        <div className="mb-2">
          <div className="mb-2">
            <Input
              placeholder="OTP kod"
              name="otp"
              value={formik.values.otp}
              onChange={formik.handleChange}
              maxLength="6"
              className="w-100"
            />
            {formik.touched.otp && formik.errors.otp && (
              <p className="error-text mt-2">{formik.errors.otp}</p>
            )}
          </div>
          <div className="auth__info mb-5">
            <span className={errMsg && 'error-text'}>
              <InfoCircle isError={Boolean(errMsg) || Boolean(otpError)} />
            </span>
            <span className={errMsg || otpError && 'error-text font-4'}>
              {errMsg || otpError ? errMsg || otpError : 'Kod telefonunuza mesaj olaraq göndərilmişdir'}
            </span>
            <RenderIf condition={!isFinished}>
              <span>{time}</span>
            </RenderIf>
            <RenderIf condition={isFinished}>
              <button disabled={isDisabled} type="button" onClick={resendOtp}>
                Yenidən göndər
              </button>
            </RenderIf>
          </div>
        </div>

        <label className="mb-2">Pin kod</label>
        <div className="showButton mb-2">
          <Input
            style={{ border: '1px solid black' }}
            placeholder="Yeni şifrə(təkrar)"
            name="pin"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.pin}
            onChange={formik.handleChange}
            className="w-100"
          />
          <div className="showButton-eye">
            <span
              className={`password-toggle-icon ${
                showPassword ? 'visible' : 'hidden'
              }`}
            >
              <RenderIf condition={showPassword}>
                <VisibilityOff
                  role="button"
                  onClick={togglePasswordVisibility}
                />
              </RenderIf>
              <RenderIf condition={!showPassword}>
                <Visibility role="button" onClick={togglePasswordVisibility} />
              </RenderIf>
            </span>
          </div>
        </div>
        {formik.touched.pin && formik.errors.pin ? (
          <div className="fv-plugins-message-container my-2">
            <div className="fv-help-block text-danger text-center">
              {formik.errors.pin}
            </div>
          </div>
        ) : null}

        <label className="my-2">Pin kod təkrar</label>
        <div className="showButton">
          <Input
            style={{ border: '1px solid black' }}
            placeholder="Yeni şifrə(təkrar)"
            name="pinCheck"
            type={showPasswordTwo ? 'text' : 'password'}
            value={formik.values.pinCheck}
            onChange={formik.handleChange}
            //   onKeyPress={handleKeyPress}
            //   maxLength="5"
            className="w-100"
          />
          <div className="showButton-eye">
            <span
              className={`password-toggle-icon ${
                showPasswordTwo ? 'visible' : 'hidden'
              }`}
            >
              <RenderIf condition={showPasswordTwo}>
                <VisibilityOff
                  role="button"
                  onClick={togglePasswordVisibilityTwo}
                />
              </RenderIf>
              <RenderIf condition={!showPasswordTwo}>
                <Visibility
                  role="button"
                  onClick={togglePasswordVisibilityTwo}
                />
              </RenderIf>
            </span>
          </div>
        </div>
        {formik.touched.pinCheck && formik.errors.pinCheck ? (
          <div className="fv-plugins-message-container my-2">
            <div className="fv-help-block text-danger text-center">
              {formik.errors.pinCheck}
            </div>
          </div>
        ) : null}
        {/* {formik.touched.pin && formik.errors.pin ? (
          <p className="error-text my-2">{formik.errors.pin}</p>
        ) : null} */}

        {/* <RenderIf condition={formik.values.pin !== formik.values.pinCheck}>
          <p className="error-text my-2">Şifrəni təkrar düzgün daxil edin</p>
        </RenderIf> */}

        <Button type="submit" className="auth__submit mt-4">
          Təsdiqlə
        </Button>

        {loading && (
          <div className="custom-progress">
            <CustomProgress />
          </div>
        )}
      </form>
    </Fragment>
  );
}
