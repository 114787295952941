import {useEffect} from "react";

import {useLocation} from "react-router-dom";

const Theme = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            document.body.setAttribute("mode", "dark");
        } else {
            document.body.removeAttribute("mode");
        }


    }, [location])

    return null
}

export default Theme