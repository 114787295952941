import { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from 'components/button/Button';
import EyeInput from 'components/EyeInput';
import { InfoCircle } from 'components/icons';
import Input from 'components/input/Input';
import RenderIf from 'components/RenderIf';
import { useFormik } from 'formik';
import useOtpTimer from 'hooks/useOtpTimer';
import { connect, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { checkRegister } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import CustomProgress from '../components/CustomProgress';

function CheckRegister(props) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const togglePasswordVisibilityTwo = () => {
    setShowPasswordTwo(!showPasswordTwo);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const { time, isFinished, isDisabled, resendOtp } = useOtpTimer(60);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const { phoneNumber } = useSelector(({ auth }) => ({
    phoneNumber: auth.phoneNumber,
  }));

  const formik = useFormik({
    initialValues: {
      otp: '',
      pin: '',
      pinCheck: '',
    },
    validationSchema: Yup.object({
      otp: Yup.string().required('OTP kodu daxil edin'),
      pin: Yup.string()
        .required('Pin kodu daxil edin')
        .min(8, 'Ən az 8 xarakter olmalıdır')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
          'Pin böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.'
        ),
      pinCheck: Yup.string().when('pin', {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref('pin')],
          'Hər iki şifrə eyni olmalıdır.'
        ),
      }),
    }),
    onSubmit: (values) => {
      enableLoading();
      checkRegister(phoneNumber, values.pin, values.otp)
        .then(({ data: { accessToken } }) => {
          disableLoading();
          props.register(accessToken);
          navigate('/');
        })
        .catch((err) => {
          setErrMsg(err.response?.data?.message ?? 'Xəta baş verdi');
          disableLoading();
        });

      formik.resetForm({
        values: { otp: '', pin: '', pinCheck: '' },
      });
    },
  });
  const handleInputValue = (field, value) => {
    formik.setFieldValue(field, value);
  };
  //   const handleKeyPress = (event) => {
  //     const regex = /^[0-9]+$/;
  //     if (!regex.test(event.key)) {
  //       event.preventDefault();
  //     }
  //   };
  return (
    <form onSubmit={formik.handleSubmit} className="auth__check-register">
      <Link to="/" className="auth__logo">
        <img src="/media/1x2-logo.svg" alt="Topaz" />
      </Link>
      <div className="auth__title">
        <span>Hesab yaradın</span>
      </div>
      <p className="auth__text-gray mb-3">
        Zəhmət olmasa tələb olunan məlumatları daxil edin
      </p>
      <div className="mb-2">
        <Input
          placeholder="OTP kod"
          name="otp"
          value={formik.values.otp}
          onChange={formik.handleChange}
          maxLength="6"
          className="w-100"
        />
        {formik.touched.otp && formik.errors.otp ? (
          <p className="error-text mt-2">{formik.errors.otp}</p>
        ) : null}
      </div>

      <div className="auth__info mb-5">
        <span className={errMsg && 'error-text'}>
          <InfoCircle isError={Boolean(errMsg)} />
        </span>
        <span className={errMsg && 'error-text font-4'}>
          {errMsg ? errMsg : 'Kod telefonunuza mesaj olaraq göndərilmişdir'}
        </span>
        <RenderIf condition={!isFinished}>
          <span>{time}</span>
        </RenderIf>
        <RenderIf condition={isFinished}>
          <button disabled={isDisabled} type="button" onClick={resendOtp}>
            Yenidən göndər
          </button>
        </RenderIf>
      </div>
      <label className="mb-2">Pin kod</label>
      <div className="mb-4 showButton">
        {/* <PinField digit={5} field="pin" onChange={handleInputValue} /> */}
        <EyeInput
          placeholder="Pin daxil edin"
          name="pin"
          type={showPassword ? 'text' : 'password'}
          value={formik.values.pin}
          onChange={formik.handleChange}
          //   onKeyPress={handleKeyPress}
          //   maxLength="5"
          className="w-100"
        />
        <div className="showButton-eye">
          <span
            className={`password-toggle-icon ${
              showPassword ? 'visible' : 'hidden'
            }`}
          >
            <RenderIf condition={showPassword}>
              <VisibilityOff role="button" onClick={togglePasswordVisibility} />
            </RenderIf>
            <RenderIf condition={!showPassword}>
              <Visibility role="button" onClick={togglePasswordVisibility} />
            </RenderIf>
          </span>
        </div>
        {formik.touched.pin && formik.errors.pin ? (
          <div className="fv-plugins-message-container mt-2 ">
            <div className="fv-help-block text-danger text-center">
              {formik.errors.pin}
            </div>
          </div>
        ) : null}
      </div>

      <label className="mb-2 mt-2">Pin kod təkrar</label>
      <div className="showButton">
        <Input
          //   style={{ border: '1px solid black' }}
          placeholder="Təkrar pin kodu daxil edin"
          name="pinCheck"
          type={showPasswordTwo ? 'text' : 'password'}
          value={formik.values.pinCheck}
          onChange={formik.handleChange}
          //   onKeyPress={handleKeyPress}
          //   maxLength="5"
          className="w-100"
        />
        <div className="showButton-eye">
          <span
            className={`password-toggle-icon ${
              showPasswordTwo ? 'visible' : 'hidden'
            }`}
          >
            <RenderIf condition={showPasswordTwo}>
              <VisibilityOff
                role="button"
                onClick={togglePasswordVisibilityTwo}
              />
            </RenderIf>
            <RenderIf condition={!showPasswordTwo}>
              <Visibility role="button" onClick={togglePasswordVisibilityTwo} />
            </RenderIf>
          </span>
        </div>
        {formik.touched.pinCheck && formik.errors.pinCheck ? (
          <p className="error-text  text-danger text-center mt-2 ">
            {formik.errors.pinCheck}
          </p>
        ) : null}
      </div>

      {/* <RenderIf condition={formik.values.pin !== formik.values.pinCheck}>
        <p className="error-text">Şifrəni təkrar düzgün daxil edin</p>
      </RenderIf> */}
      <Button type="submit" className="auth__submit mt-4">
        Təsdiqlə
      </Button>
      {loading && (
        <div className="custom-progress">
          <CustomProgress />
        </div>
      )}
    </form>
  );
}

export default connect(null, auth.actions)(CheckRegister);
