import {useEffect} from 'react';

import RenderIf from "components/RenderIf";
import Theme from 'components/theme';
import {actions} from 'modules/auth/_redux/authRedux';
import ResultPage from 'pages/ResultPage';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import PrivateRoutes from './PrivateRoutes';
import {useAuth} from '../hooks';
import UpdateBirthDate from "../modules/auth/components/UpdateBirthDate";
import Logout from '../modules/auth/pages/Logout';
import AuthPage from '../pages/AuthPage';
import BronPage from '../pages/BronPage';
import CirculationsPage from '../pages/CirculationsPage';
import HomePage from '../pages/HomePage';

export function Router() {
    const isAuth = useAuth();

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth);

    useEffect(() => {
        if (isAuth) dispatch(actions.getProfile());
    }, [dispatch, isAuth]);

    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/results" element={<ResultPage/>}/>
                <Route path="/bron" element={<BronPage/>}/>
                <Route path="/circulations/*" element={<CirculationsPage/>}/>
                <Route path="/logout/*" element={<Logout/>}/>
                {isAuth ? (
                    <Route path="/*" element={<PrivateRoutes/>}/>
                ) : (
                    <Route path="/auth/*" element={<AuthPage/>}/>
                )}
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
            <Theme/>
            <ToastContainer/>
            <RenderIf condition={user.id && typeof user.birthDate !== "undefined" && !user.birthDate}>
                <UpdateBirthDate/>
            </RenderIf>
        </>
    );
}
