import React, {useState, Fragment} from 'react';

import Button from "components/button/Button";
import DateInput from "components/input/DateInput";
import PhoneInput from 'components/input/PhoneInput';
import RenderIf from "components/RenderIf";
import {useFormik} from 'formik';
import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import {register} from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import CustomProgress from '../components/CustomProgress';

function Register(props) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const formik = useFormik({
        initialValues: {
            number: '',
            birthDate: ''
        },
        validationSchema: Yup.object({
            number: Yup.string().required('Nömrə tələb olunur.'),
            birthDate: Yup.string().required('Doğum tarixi tələb olunur.')
        }),
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true);
            enableLoading();
            const regex = /[^0-9]/gm;
            let input = values.number;
            const newInput = input.replace(regex, '');
            const body = {
                number: newInput,
                birthDate: values.birthDate
            }
            register(body)
                .then(() => {
                    formik.resetForm();
                    props.fulfillPhoneNumber(newInput);
                    setSubmitting(false);
                    navigate('/auth/register/checkregister');
                })
                .catch((error) => {
                    setSubmitting(false);
                    disableLoading();
                    const message = error.response?.data?.errors?.["BirthDate"]?.[0] ?? error.response?.data?.message;
                    setErrMsg(message);
                })
                .finally(() => disableLoading());
        },
    });

    const handleChange = (e) => {
        formik.setFieldValue('number', e.target.value);
    };

    return (
        <Fragment>
            <Helmet>
                <title>Qeydiyyatdan keç</title>
                <meta name="description" content="1x2 Qeydiyyat"/>
            </Helmet>
            <form onSubmit={formik.handleSubmit} className="auth__register">
                <Link to="/" className="auth__logo">
                    <img src="/media/1x2-logo.svg" alt="Topaz"/>
                </Link>
                <div className="auth__title">
                    <span>Hesab yaradın</span>
                </div>
                <p className="auth__text-gray mb-5">Zəhmət olmasa tələb olunan məlumatları daxil edin</p>
                <div className="d-flex flex-column gap-2 mb-4">
                    <label className="font-4 fw-500">Telefon nömrəsi</label>
                    <PhoneInput
                        name="number"
                        id="number"
                        onChange={handleChange}
                        value={formik.values.number}
                    />
                    {formik.touched.number && formik.errors.number ? (
                        <div className="error-text">
                            <span>{formik.errors.number}</span>
                        </div>
                    ) : null}
                </div>
                <div className="d-flex flex-column gap-2 mb-4">
                    <label className="font-4 fw-500">Doğum tarixi</label>
                    <DateInput
                        name="birthDate"
                        onChange={(val) => formik.setFieldValue('birthDate', val)}
                        onTouch={formik.setFieldTouched}
                    />
                    {formik.touched.birthDate && formik.errors.birthDate ? (
                        <div className="error-text">
                            <span>{formik.errors.birthDate}</span>
                        </div>
                    ) : null}
                    <RenderIf condition={errMsg}>
                        <p className="error-text">{errMsg}</p>
                    </RenderIf>
                </div>
                <div className="d-flex gap-4 auth__register-buttons">
                    <Button variant="secondary" onClick={() => navigate('/auth/login')}>Ləğv et</Button>
                    <Button type="submit">İrəli</Button>
                </div>
            </form>
            {loading && (
                <div className="custom-progress">
                    <CustomProgress/>
                </div>
            )}
        </Fragment>
    );
}

export default connect(null, auth.actions)(Register);
