import React from "react";

const Topaz = () => (
    <svg width="125" height="36" viewBox="0 0 125 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M116.485 8.22066L120.858 2.78945H101.334L100.562 8.22066H110.104L96.6262 24.964H116.559L117.33 19.5326H107.378L116.485 8.22066Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M90.2074 16.3698C88.5877 19.4388 85.386 20.8831 83.0586 19.5966C80.7306 18.3103 80.1577 14.7782 81.7796 11.7097C83.4 8.63969 86.6008 7.19539 88.9281 8.4817C91.2564 9.76752 91.8293 13.2998 90.2074 16.3698ZM91.9071 2.79256L91.6657 4.49085C87.7986 0.630814 81.4173 1.73621 77.7275 6.7397C73.5578 12.3914 73.5122 18.9404 77.017 22.8902C79.9204 26.1623 85.0856 26.1311 88.978 23.385L88.7521 24.964H94.91L98.0645 2.79256H91.9071Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.2488 8.22084L23.2908 2.78955H17.4954H13.7451H12.0591H11.1647H6.21189H5.72926H0.757671L-7.18724e-06 8.22084H4.9721H5.45447H7.02106L3.88805 30.2458H4.77514H8.28137H10.0444L13.3315 8.22084H16.7381H19.2488Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M66.2479 15.7098C64.6848 18.7704 61.5159 20.2347 59.1729 18.9807C56.8289 17.7273 56.1977 14.2307 57.7619 11.1695C59.3253 8.10955 62.4946 6.64526 64.8376 7.89988C67.1802 9.15312 67.8131 12.6505 66.2479 15.7098M70.2692 3.95801C66.9323 1.0272 62.4776 1.39856 58.6844 4.46922L58.923 2.793H52.765L48.0391 35.9999H54.1966L56.0857 22.7238C59.7234 26.3974 66.2143 25.2485 70.2794 20.4005C74.6632 15.1711 74.6589 7.80972 70.2692 3.95801"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.0866 19.4878C38.5558 24.8025 33.2326 27.4785 29.1946 25.467C25.1577 23.4531 23.9381 17.514 26.4687 12.2004C29.0003 6.8871 34.3243 4.21023 38.3603 6.22307C42.3973 8.23486 43.618 14.1738 41.0866 19.4878ZM44.4724 3.08983C38.5586 -2.10088 28.9756 -0.598131 23.068 6.44515C17.1608 13.4884 17.1692 23.408 23.0826 28.5998C28.9975 33.7906 38.5794 32.2878 44.4877 25.2435C50.3941 18.1998 50.387 8.28005 44.4724 3.08983Z"/>

    </svg>
)

export default Topaz;
