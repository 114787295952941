import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actions } from '../_redux/authRedux';

function Logout() {
  const dispatch = useDispatch();
  const { hasAuthToken } = useSelector(({ auth }) => ({
    hasAuthToken: Boolean(auth.accessToken),
  }));

  useEffect(() => {
    dispatch(actions.logout());
  }, []);

  const navigate = useNavigate();

  if (hasAuthToken) {
    return null;
  } else {
    navigate('/');
    return null;
  }
}

export default Logout;
