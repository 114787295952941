import Layout from 'components/layout';
import CirculationList from 'modules/circulations/circulation-list';
import CirculationTickets from 'modules/circulations/circulation-tickets';
import { Route, Routes } from 'react-router-dom';

const CirculationsPage = () => (
  <Routes>
    <Route
      path="/*"
      element={
        <Layout>
          <CirculationList />
        </Layout>
      }
    />
    <Route
      path="/:id/tickets"
      element={
        <Layout>
          <CirculationTickets />
        </Layout>
      }
    />
  </Routes>
);

export default CirculationsPage;
