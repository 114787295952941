import React from "react";

import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    container: {
        borderRadius: "8px",
        background: "#FFF",
        padding: "12px 0",
        width: "fit-content"
    },
    title: {
        color: "#323232",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: "12px 24px"
    },
    tableHead: {
        background: "#EAF6FF",
        borderTop: "1px solid #E3E3E3",
        borderBottom: "1px solid #E3E3E3"
    },
    tableHeadCell: {
        padding: "8px 64px 8px 0",
        '&:first-child': {
            paddingLeft: "24px"
        },
        '&:last-child': {
            paddingRight: "24px"
        },
        color: "#989898",
        fontSize: "14px",
        fontWeight: 500,
    },
    tableDataCell: {
        padding: "12px 0",
        '&:first-child': {
            paddingLeft: "24px"
        }
    }
});

const CirculationInfo = ({data}) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <h1 className={classes.title}>Tur haqqında məlumatlar</h1>
            <div className="table-end">
                <table>
                    <thead className={classes.tableHead}>
                    <tr>
                        <th className={classes.tableHeadCell}>Məhsul</th>
                        <th className={classes.tableHeadCell}>Tur</th>
                        <th className={classes.tableHeadCell}>Oyun Sayı</th>
                        <th className={classes.tableHeadCell}>Uduş</th>
                        <th className={classes.tableHeadCell}>Uduşlu Bilet Sayı</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={classes.tableDataCell}>{data.productName}</td>
                        <td className={classes.tableDataCell}>{data?.code}</td>
                        <td className={classes.tableDataCell}>{data?.gameCount}</td>
                        <td className={classes.tableDataCell}>{data?.winningAmount} AZN</td>
                        <td className={`${classes.tableDataCell} text-center pe-4`}>{data?.winTicketCount}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CirculationInfo;