import {useState} from 'react';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {Button, Modal} from 'react-bootstrap';

const Filter = ({
                    show,
                    onHide,
                    filterInputs,
                    filterInputsUpdate,
                    setFilter,
                    resetFilter
                }) => {
    const [isExpired, setIsExpired] = useState(
        filterInputs.isExpired === '' ? 'all' : filterInputs.isExpired
    );
    const [openIsExpired, setOpenIsExpired] = useState(false);

    function handleChange(event, handlerFunc) {
        const value = event.target.value;
        console.log(value)
        handlerFunc(value);
        filterInputsUpdate(event);
        setFilter(prevState => ({
            ...prevState, skip: 0
        }))
    }

    return (
        <>
            <Modal
                size="md"
                show={show}
                onHide={() => onHide(false)}
                aria-labelledby="create"
                centered
                className="modal-right pr-0"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="create">Tur filteri</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="code"
                                        name="code"
                                        label="Tur kodu"
                                        placeholder="Tur kodu"
                                        className="w-100"
                                        value={filterInputs.code}
                                        onChange={(e) => filterInputsUpdate(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <InputLabel htmlFor="expiredDate">
                                        Turun başlama tarixi
                                    </InputLabel>
                                    <TextField
                                        type="date"
                                        id="expiredDate"
                                        name="expiredDate"
                                        className="w-100"
                                        value={filterInputs.expiredDate}
                                        onChange={(e) => filterInputsUpdate(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <InputLabel htmlFor="resultedDate">
                                        Turun bitmə tarixi
                                    </InputLabel>
                                    <TextField
                                        type="date"
                                        id="resultedDate"
                                        name="resultedDate"
                                        className="w-100"
                                        value={filterInputs.resultedDate}
                                        onChange={(e) => filterInputsUpdate(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="productName"
                                        name="productName"
                                        label="Məhsul adı"
                                        placeholder="Məhsul adı"
                                        className="w-100"
                                        value={filterInputs.productName}
                                        onChange={(e) => filterInputsUpdate(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="totalAmount"
                                        name="totalAmount"
                                        label="Hovuz məbləği"
                                        placeholder="Hovuz məbləği"
                                        className="w-100"
                                        value={filterInputs.totalAmount}
                                        onChange={(e) => filterInputsUpdate(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <FormControl className="w-100">
                                        <InputLabel id="isExpired">Bitib/Aktiv</InputLabel>
                                        <Select
                                            label="Bitib/Aktiv"
                                            labelId="isExpired"
                                            open={openIsExpired}
                                            onClose={() => setOpenIsExpired(false)}
                                            onOpen={() => setOpenIsExpired(true)}
                                            value={isExpired}
                                            onChange={(e) => handleChange(e, setIsExpired)}
                                            inputProps={{
                                                name: 'isExpired',
                                                id: 'isExpired',
                                            }}
                                        >
                                            <MenuItem value="all">Hamısı</MenuItem>
                                            <MenuItem value="true">Bitib</MenuItem>
                                            <MenuItem value="false">Aktiv</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div
                                    style={{gap: '12px'}}
                                    className="w-100 pt-2 pb-3 d-flex justify-content-center"
                                >
                                    <Button
                                        className="w-100"
                                        variant="success"
                                        onClick={() => onHide(false)}
                                    >
                                        Tətbiq et
                                    </Button>

                                    <Button
                                        className="w-100"
                                        variant="danger"
                                        onClick={() => {
                                            resetFilter();
                                            setIsExpired('all')
                                        }}
                                    >
                                        Təmizlə
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Filter;
