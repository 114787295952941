import React, { useState } from 'react';

import UserIcon from 'components/icons/UserIcon';
import { otp } from 'modules/auth/_redux/authCrud';
import { actions } from 'modules/home/_redux/PlayTicketsRedux';
import Dropdown from 'react-bootstrap/Dropdown';
import { NumericFormat } from 'react-number-format';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ChangePasswordModal from '../../modals/ChangePassword';
import '../styles/style.css';

export default function UserProfileDropdown() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { setPage } = actions;
  const { isAuth, phoneNumber } = useSelector(
    ({ auth }) => ({
      isAuth: auth.accessToken !== undefined,
      phoneNumber: auth.phoneNumber,
    }),
    shallowEqual
  );
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');

  async function handleChangePassword() {
    try {
      await otp(phoneNumber);
      setShow(true);
      setError('');
    } catch (err) {
      setShow(true);
      setError(err?.response?.data?.message);
    }
  }

  function routeToLogin() {
    navigate('/auth/login');
    dispatch(setPage(location.pathname));
  }

  if (isAuth) {
    return (
      <React.Fragment>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <UserIcon />
            <span className="dropdown-number">
              <NumericFormat
                value={phoneNumber}
                displayType={'text'}
                prefix={'+'}
                format="+### ## ### ## ##"
              />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="dropdown-menu__item">
              <div className="dropdown-menu__title">
                <p>+{phoneNumber}</p>
              </div>
              <div className="dropdown-menu__button">
                <button onClick={handleChangePassword}>Şifrəni dəyiş</button>
              </div>
              <div className="dropdown-menu__button">
                <button
                  className="button-red"
                  onClick={() => navigate('/logout')}
                >
                  Çıxış et
                </button>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
        <ChangePasswordModal
          show={show}
          onHide={setShow}
          phoneNumber={phoneNumber}
          errorStatus={error}
        />
      </React.Fragment>
    );
  }

  return (
    <div className="header-container-item">
      <button onClick={routeToLogin} className="user-container">
        <UserIcon />
      </button>
    </div>
  );
}
