import React, {useEffect, useState} from "react";

import axios from "axios";
import {Helmet} from "react-helmet-async";

import Circulations from "./components/circulations";
import Description from "./components/description";
import FAQ from "./components/faq";
import Hero from "./components/hero";

import "./Home.css";

const Home = () => {
    const [circulationData, setCirculationData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`/circulations/lastCirculationAmount`);
                setCirculationData(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();

        const interval = setInterval(fetchData, 15000);

        return () => clearInterval(interval)
    }, []);

    return(
        <div className="home">
            <Helmet>
                <title>Ana səhifə | 1x2</title>
                <meta name="description" content="Turlar | 1x2"/>
            </Helmet>
            <Hero circulationData={circulationData}/>
            <Circulations circulationData={circulationData}/>
            <Description/>
            <FAQ/>
        </div>
    )
}

export default Home