import { SwipeableDrawer } from '@mui/material';
import { Close } from 'components/icons';
import { useAuth } from 'hooks';
import {createUseStyles} from 'react-jss'
import { Link } from 'react-router-dom';

import ActiveLink from '../ActiveLink';
import RenderIf from '../RenderIf';

import './MobileMenu.css';

const useStyles = createUseStyles({
  drawer: {
    '& .MuiDrawer-paper': {
      maxWidth: '375px',
      width: '100%',
    },
  },
});
const MobileMenu = ({ open, onHide }) => {
  const classes = useStyles();

  const isAuth = useAuth();

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => onHide(false)}
      onOpen={() => null}
      className={classes.drawer}
    >
      <div className="mobile-menu__title">
        <button
          onClick={() => onHide(false)}
          className="mobile-menu__close-button"
        >
          <Close />
        </button>
        <Link to="/" className="mobile-menu__logo">
          <img src="/media/1x2-logo.svg" alt="1x2" />
        </Link>
      </div>
      <ul className="mobile-menu__list">
        <li>
          <ActiveLink to="/" activeClassName="mobile-menu__active">
            Ana səhifə
          </ActiveLink>
        </li>
        <li>
          <ActiveLink to="/bron" activeClassName="mobile-menu__active">
            Bron et
          </ActiveLink>
        </li>
        <li>
          <ActiveLink to="/circulations" activeClassName="mobile-menu__active">
            Turlar
          </ActiveLink>
        </li>
        <li>
          <ActiveLink to="/results" activeClassName="mobile-menu__active">
            Nəticələr
          </ActiveLink>
        </li>
        <RenderIf condition={Boolean(isAuth)}>
          <li>
            <ActiveLink to="/my-tickets" activeClassName="mobile-menu__active">
              Biletlərim
            </ActiveLink>
          </li>
          <li>
            <ActiveLink
              to="/my-bron-tickets"
              activeClassName="mobile-menu__active"
            >
              Bron edilmiş kuponlar
            </ActiveLink>
          </li>
        </RenderIf>
      </ul>
    </SwipeableDrawer>
  );
};

export default MobileMenu;
