import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {put, takeLatest} from 'redux-saga/effects';

import {getPhoneNumberByToken} from './authCrud';

export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    LogoutRequest: '[Request Logout] Action',
    Register: '[Register] Action',
    PhoneNumberRequested: '[Request PhoneNumber] Action',
    FulFillUser: '[FulFill User] Action',
    SetPhoneNumber: '[Set PhoneNumber] Action',
    RefreshToken: '[Refresh Token] Action',
    FulFillPhoneNumber: '[Fulfill Phone Number] Action',
    GetProfile: '[GET Profile]',
};

const initialAuthState = {
    phoneNumber: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    expiredDate: undefined,
    checkToken: undefined,
};

export const reducer = persistReducer(
    {
        storage,
        key: '1x2',
        whitelist: [
            'phoneNumber',
            'accessToken',
            'refreshToken',
            'expiredDate',
            'id',
            'birthDate'
        ],
    },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
                const {accessToken, refreshToken, expiredDate} = action.payload;
                return {
                    accessToken,
                    refreshToken,
                    expiredDate,
                };
            }

            case actionTypes.Register: {
                const {accessToken} = action.payload;
                return {accessToken};
            }

            case actionTypes.LogoutRequest: {
                return initialAuthState;
            }

            case actionTypes.FulFillUser: {
                return {...state, ...action.payload};
            }

            case actionTypes.FulFillPhoneNumber: {
                return {...state, phoneNumber: action.payload};
            }

            case actionTypes.SetPhoneNumber: {
                const {phoneNumber} = action.payload;
                return {...state, phoneNumber};
            }

            case actionTypes.RefreshToken: {
                const {accessToken, expiredDate, refreshToken, checkToken} =
                    action.payload;

                return {
                    ...state,
                    accessToken,
                    expiredDate,
                    refreshToken,
                    checkToken,
                };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    login: (accessToken, refreshToken, expiredDate) => ({
        type: actionTypes.Login,
        payload: {accessToken, refreshToken, expiredDate},
    }),
    register: (accessToken) => ({
        type: actionTypes.Register,
        payload: {accessToken},
    }),
    logout: () => ({
        type: actionTypes.Logout,
    }),
    requestLogout: () => ({
        type: actionTypes.LogoutRequest,
    }),
    requestPhoneNumber: (phoneNumber) => ({
        type: actionTypes.PhoneNumberRequested,
        payload: {phoneNumber},
    }),
    fulfillUser: (data) => ({
        type: actionTypes.FulFillUser,
        payload: data,
    }),
    fulfillPhoneNumber: (phoneNumber) => ({
        type: actionTypes.FulFillPhoneNumber,
        payload: phoneNumber,
    }),
    setPhoneNumber: (phoneNumber) => ({
        type: actionTypes.SetPhoneNumber,
        payload: {phoneNumber},
    }),
    setRefreshToken: (accessToken, expiredDate, refreshToken) => ({
        type: actionTypes.RefreshToken,
        payload: {accessToken, expiredDate, refreshToken},
    }),
    getProfile: () => ({
        type: actionTypes.GetProfile,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestPhoneNumber());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestPhoneNumber());
    });

    yield takeLatest(
        actionTypes.PhoneNumberRequested,
        function* phoneNumberRequested() {
            const {data} = yield getPhoneNumberByToken();
            yield put(
                actions.fulfillUser({
                    phoneNumber: data?.phonenumber,
                    id: data?.id,
                })
            );
        }
    );
    yield takeLatest(actionTypes.GetProfile, function* profileRequested() {
        try {
            const {data} = yield getPhoneNumberByToken();
            yield put(
                actions.fulfillUser({
                    phoneNumber: data?.phonenumber,
                    birthDate: data?.birthDate,
                    id: data?.id,
                })
            );
        } catch {
        }
    });

    yield takeLatest(actionTypes.Logout, function* logoutRequest() {
        yield put(actions.requestLogout());
    });
}
