import React from "react";

import DateInput from "components/input/DateInput";
import {useFormik} from "formik";
import {Modal, Button} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import * as Yup from "yup";

import {updateBirthDate} from "../_redux/authCrud";
import {actions} from "../_redux/authRedux";

const UpdateBirthDate = () => {
    const dispatch = useDispatch();

    const {birthDate, isAuth, id} = useSelector(state => ({
        birthDate: state.auth?.birthDate,
        isAuth: Boolean(state.auth.accessToken),
        id: state.auth?.id
    }));

    const formik = useFormik({
        initialValues: {
            birthDate: "",
            customerId: +id
        },
        validationSchema: Yup.object({
            birthDate: Yup.string().required()
        }),
        onSubmit: async (values) => {
            try {
                await updateBirthDate(values);
                dispatch(actions.getProfile());
            } catch (err) {

            }
        }
    })

    return (
        <Modal show={!birthDate && isAuth} centered>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <p className="mb-2 p-4 text-center fw-500">Mərc etməyə davam etmək üçün 18 yaşınız olmalıdır.
                        Zəhmət olmasa, doğum tarixinizi daxil edin
                    </p>
                    <DateInput
                        name="birthDate"
                        onChange={(value) => formik.setFieldValue("birthDate", value)}
                        onTouch={formik.setFieldTouched}
                    />
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button type="submit" variant="danger">Təsdiqlə</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default UpdateBirthDate