import * as React from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import AlertComponent from 'components/alert';
import RenderIf from 'components/RenderIf';
import { format } from 'date-fns';
import CustomProgress from 'modules/auth/components/CustomProgress';
import {createUseStyles} from 'react-jss'
import Select from 'react-select';
import '../home/styles/style.scss';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(2n)': {
    backgroundColor: '#EAF6FF',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells = [
  {
    id: 'id',
    label: '№',
  },
  {
    id: 'date',
    label: 'Tarix',
  },
  {
    id: 'game',
    label: 'Oyunlar',
  },
  {
    id: 'score',
    label: 'Hesab',
  },
  {
    id: 'gameOptionName',
    label: 'Nəticə',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy} = props;

  const useStyles = createUseStyles({
    activeHeadCell: {
      borderBottom: '1px solid #24A3FF !important',
      color: '#24A3FF !important',
    },
    activeDirectionRow: {
      color: '#24A3FF !important',
    },
    TableRow: {
      padding: '0 35px !important',
    },
  });

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.TableRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  setIsLoading,
  isLoading,
  setAllData,
}) {
  const [order] = React.useState('desc');
  const [orderBy] = React.useState('createdDate');
  const [selected] = React.useState([]);
  const [dense] = React.useState(false);
  const [options, setOptions] = React.useState();

  const [statusResult, setStatusResult] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [isLoadingStatus, setIsLoadingStatus] = React.useState(false);

  const dataStatusOptions = options?.map((item) => ({
    label: item?.dateRange,
    value: item?.id,
  }));
  const onGetTicket = () => {
    setIsLoading(true);
    axios
      .get(
        `result/circulationResults?circulationId=${
          statusResult?.value ?? dataStatusOptions?.[0]?.value
        }`
      )
      .then(({ data }) => {
        setRows(data.circulationGames);
        setAllData(prevState => ({
          ...prevState, ...data
        }));
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
    axios.get(`circulations/id?id=${statusResult?.value ?? dataStatusOptions?.[0]?.value}`).then(res => {
        setAllData(prevState => ({
            ...prevState,
            code: res.data.code,
            productName: res.data.productName
        }))
    }).catch(() => null)
  };

  React.useEffect(() => {
    axios
        .get(`result/circulationsByDate`)
        .then(({ data }) => {
          setOptions(data);
          setIsLoadingStatus(false);
        })
        .catch(() => setIsLoadingStatus(false));
  }, []);

  React.useEffect(() => {
    if(options) {
      onGetTicket()
    }
  }, [statusResult, options]);


  const useStyles = createUseStyles({
    TableCell: {
      padding: '14px 35px !important',
    },
    blue: {
      color: '#24A3FF',
    },
    red: {
      color: '#FF0009',
    },
    gray: {
      color: 'rgb(153, 153, 153)',
    },
    success: {
      color: '#1dd419',
    },
    warning: {
      color: 'orange',
    },
    button: {
      background: 'unset',
      '& svg': {
        width: '20px',
        height: '20px',
        color: '#0996ed',
      },
    },
  });

  const classes = useStyles();

  function CustomSelect() {
    const winningStatusHandler = (e) => {
      setStatusResult(e);
    };

    return (
      <>
        <RenderIf condition={!isLoadingStatus}>
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="custom-select winning-status">
                <label className="custom-select__label">Turlar</label>
                <Select
                  className="mw-100"
                  options={dataStatusOptions}
                  value={statusResult || dataStatusOptions?.[0]}
                  onChange={winningStatusHandler}
                />
              </div>
            </div>
          </div>
        </RenderIf>
      </>
    );
  }

  return (
    <>
      <Box sx={{ width: '100%' }} className="custom-table">
        <RenderIf condition={!isLoading}>
          <CustomSelect />
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  rowCount={rows.length}
                />
                <TableBody sx={{ whiteSpace: 'nowrap' }}>
                  {rows.map((row, index) => (
                    <StyledTableRow tabIndex={-1} key={row.id}>
                      <TableCell padding="none" align="center" className={classes.TableCell}>
                        {++index}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        {format(new Date(row.date), 'dd.MM.yyyy HH:mm:ss')}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        {row.game}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        {row.score || 'Məlum deyil'}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        <span>
                          {row.gameOptionName === 'Host'
                            ? '1'
                            : row.gameOptionName === 'Draw'
                            ? 'X'
                            : row.gameOptionName === 'Guest'
                            ? '2'
                            : row.gameOptionName === 'Unknown'
                            ? 'Məlum deyil'
                            : row.gameOptionName === 'Postponed'
                            ? 'Təxirə salınıb'
                            : 'Məlum deyil'}
                        </span>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <RenderIf condition={!rows.length && !isLoading}>
                <AlertComponent message="Biletiniz yoxdur." />
              </RenderIf>
            </TableContainer>
          </Paper>
        </RenderIf>
        <RenderIf condition={isLoading}>
          <CustomProgress />
        </RenderIf>
      </Box>
    </>
  );
}
