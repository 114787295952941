import LottiePlayer from 'components/lottie-player';
import scrollAnimation from 'components/lottie-player/animations/scroll-down.json';
import { Link } from 'react-router-dom';

import './Hero.css';

const Hero = ({circulationData}) => {
  return (
    <div className="hero">
      <div className="container hero__container">
        <div className="hero__caption">
          <p className="hero__title">HOVUZA BAŞ VUR</p>
          <p className="hero__description">CEKPOTU HƏDƏFLƏ!</p>
          <p className="hero__amount">
            <span>{circulationData?.jackpotAmount}</span>
            <span>₼</span>
          </p>
          <Link to="/bron" className="hero__link">
            İndi bron et
          </Link>
        </div>
        <div className="hero__scroll">
          <span className="hero__scroll-text">Aşağı sürüşdür</span>
          <LottiePlayer
            animation={scrollAnimation}
            width="56px"
            height="56px"
          />
        </div>
      </div>
      <div className="hero__gradient"></div>
    </div>
  );
};

export default Hero;
