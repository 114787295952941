import {useEffect, useState} from 'react';

// import/order
import AlertComponent from 'components/alert';
import {FilterButton} from 'components/button';
import Loading from 'components/loading';
import RenderIf from 'components/RenderIf';
import {paramsObjectToString} from 'helpers';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate} from 'react-router-dom';

import {updateObject} from '../../../helpers/updateData';
import CustomPagination from "../../home/components/CustomPagination";
import useCirculationAll from '../../home/hooks/useCirculationAll';
import Filter from '../../home/modals/Filter';
import Circulation from '../components/Circulation';
import './Circulations.css';

const initialState = {
    skip: 0,
    take: 10,
    code: '',
    resultedDate: '',
    expiredDate: '',
    productName: '',
    totalAmount: '',
    possibleSeeTotalAmount: '',
    isExpired: '',
    isActive: '',
    sortField: 'expiredDate',
    orderBy: false
};

const CirculationList = () => {
    const location = useLocation();
    const [showFilter, setShowFilter] = useState(false);
    const [filterInputs, setFilterInputs] = useState(initialState);

    const navigate = useNavigate();

    const {data, loading, totalCount} = useCirculationAll();

    const handleClick = () => {
        setShowFilter((prevState) => !prevState);
    };

    const filterInputsUpdate = (event) => {
        const updatedData = updateObject(
            filterInputs,
            event.target.name === 'isExpired'
                ? {
                    [event.target.name]:
                        event.target.value === 'all' ? '' : event.target.value,
                    isActive:
                        event.target.value === 'all'
                            ? ''
                            : event.target.value === 'false',
                    skip: 0
                }
                : {
                    [event.target.name]: event.target.value,
                    skip: 0
                }
        );
        const query = paramsObjectToString(updatedData);

        setFilterInputs(updatedData);
        navigate(`/circulations?${query}`);
    };

    const onChangePage = (skip) => {
        const updatedData = updateObject(
            filterInputs,
            {skip}
        );
        const query = paramsObjectToString(updatedData);
        setFilterInputs(updatedData);
        navigate(`/circulations?${query}`);
    }

    const resetFilter = () => {
        setFilterInputs(initialState);
        const query = paramsObjectToString(initialState);
        navigate(`/circulations?${query}`);
    }

    useEffect(() => {
        if (location.pathname === '/circulations' && !location.search) {
            const query = paramsObjectToString(filterInputs);
            navigate(`/circulations?${query}`);
        }
    }, [location.search, location.pathname, navigate, filterInputs]);

    useEffect(() => {
        const filterInputs = {};
        const params = new URLSearchParams(location.search);
        for (const [key, value] of params.entries()) {
            filterInputs[key] = value;
        }
        setFilterInputs(filterInputs);
    }, [location.search])
    return (
        <>
            <Helmet>
                <title>Turlar | 1x2</title>
                <meta name="description" content="Turlar | 1x2"/>
            </Helmet>
            <div className="bg-gray py-4">
                <div className="container">
                    <div className="circulations__filter">
                        <FilterButton onClick={handleClick}/>
                    </div>
                    <RenderIf condition={!loading}>
                        {data
                            ?.sort((a, b) =>
                                a.canBePlayed === b.canBePlayed ? 0 : a.canBePlayed ? -1 : 1
                            )
                            .map((circulation) => (
                                <Circulation key={circulation.id} data={circulation}/>
                            ))}
                        <RenderIf condition={totalCount > filterInputs.take}>
                            <CustomPagination
                                totalCount={totalCount}
                                onChange={onChangePage}
                            />
                        </RenderIf>
                    </RenderIf>
                    <RenderIf condition={loading}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <Loading/>
                        </div>
                    </RenderIf>
                    <RenderIf condition={!data?.length && !loading}>
                        <AlertComponent variant="error" message="Məlumat tapılmadı"/>
                    </RenderIf>
                </div>
                <RenderIf condition={showFilter}>
                    <Filter
                        show={showFilter}
                        onHide={setShowFilter}
                        filterInputs={filterInputs}
                        filterInputsUpdate={filterInputsUpdate}
                        initialState={initialState}
                        setFilter={setFilterInputs}
                        navigate={navigate}
                        resetFilter={resetFilter}
                    />
                </RenderIf>
            </div>
        </>
    );
};

export default CirculationList;
