import React from "react";

import {useSelector} from "react-redux";
import {Navigate, Route, Routes} from "react-router-dom";

import CheckRegister from "../modules/auth/check-register/CheckRegister";
import ForgotPass from "../modules/auth/forgot-password/ForgotPassword";
import AuthLayout from "../modules/auth/Layout";
import LogIn from "../modules/auth/login/LogIn";
import NewPass from "../modules/auth/new-password/NewPassword";
import Register from "../modules/auth/register/Register";

const AuthPage = () => {
    const {phoneNumber} = useSelector(state => state.auth);

    return (
        <Routes>
            <Route path="/" element={<Navigate replace to="/auth/login"/>}/>
            <Route path="/*" element={<AuthLayout/>}>
                <Route path="login" exact element={<LogIn/>}/>
                <Route path="register" element={<Register/>}/>
                {phoneNumber ? (
                    <Route
                        path="register/checkregister"
                        element={<CheckRegister/>}
                    />
                ) : (
                    <Route
                        path="register/checkregister"
                        element={<Navigate replace to="/auth/register"/>}
                    />
                )}

                <Route path="forgot-password" element={<ForgotPass/>}/>

                {phoneNumber ? (
                    <Route path="forgot-password/new-password" element={<NewPass/>}/>
                ) : (
                    <Route
                        path="forgot-password/new-password"
                        element={<Navigate replace to="/auth/forgot-password"/>}
                    />
                )}
            </Route>
        </Routes>
    )
}

export default AuthPage