import React from "react";

const Cup = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <g clipPath="url(#clip0_1894_2254)">
            <path d="M37.5 21H36C35.6022 21 35.2206 20.842 34.9393 20.5607C34.658 20.2794 34.5 19.8978 34.5 19.5V1.5C34.5 1.10218 34.658 0.720644 34.9393 0.43934C35.2206 0.158035 35.6022 0 36 0L37.5 0C40.2848 0 42.9555 1.10625 44.9246 3.07538C46.8938 5.04451 48 7.71523 48 10.5C48 13.2848 46.8938 15.9555 44.9246 17.9246C42.9555 19.8938 40.2848 21 37.5 21ZM37.5 3V18C39.4891 18 41.3968 17.2098 42.8033 15.8033C44.2098 14.3968 45 12.4891 45 10.5C45 8.51088 44.2098 6.60322 42.8033 5.1967C41.3968 3.79018 39.4891 3 37.5 3ZM12 21H10.5C7.71523 21 5.04451 19.8938 3.07538 17.9246C1.10625 15.9555 0 13.2848 0 10.5C0 7.71523 1.10625 5.04451 3.07538 3.07538C5.04451 1.10625 7.71523 0 10.5 0L12 0C12.3978 0 12.7794 0.158035 13.0607 0.43934C13.342 0.720644 13.5 1.10218 13.5 1.5V19.5C13.5 19.8978 13.342 20.2794 13.0607 20.5607C12.7794 20.842 12.3978 21 12 21ZM10.5 3C8.51088 3 6.60322 3.79018 5.1967 5.1967C3.79018 6.60322 3 8.51088 3 10.5C3 12.4891 3.79018 14.3968 5.1967 15.8033C6.60322 17.2098 8.51088 18 10.5 18V3Z" fill="#FF0009"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21 30V37.5H27V30H21ZM19.5 27H28.5C29.3284 27 30 27.6716 30 28.5V39C30 39.8284 29.3284 40.5 28.5 40.5H19.5C18.6716 40.5 18 39.8284 18 39V28.5C18 27.6716 18.6716 27 19.5 27Z" fill="#FF0009"/>
            <path d="M37.5 0H10.5V21C10.5 23.3869 11.4482 25.6761 13.136 27.364C14.8239 29.0518 17.1131 30 19.5 30H28.5C30.8869 30 33.1761 29.0518 34.864 27.364C36.5518 25.6761 37.5 23.3869 37.5 21V0Z" fill="#800004"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0H37.5V21C37.5 23.3869 36.5518 25.6761 34.864 27.364C33.1761 29.0518 30.8869 30 28.5 30H19.5C17.1131 30 14.8239 29.0518 13.136 27.364C11.4482 25.6761 10.5 23.3869 10.5 21V0ZM34.5 3H13.5V21C13.5 22.5913 14.1321 24.1174 15.2574 25.2426C16.3826 26.3679 17.9087 27 19.5 27H28.5C30.0913 27 31.6174 26.3679 32.7426 25.2426C33.8679 24.1174 34.5 22.5913 34.5 21V3Z" fill="#FF0009"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M31.5 40.5H16.5C15.7044 40.5 14.9413 40.8161 14.3787 41.3787C13.8161 41.9413 13.5 42.7044 13.5 43.5V45H34.5V43.5C34.5 42.7044 34.1839 41.9413 33.6213 41.3787C33.0587 40.8161 32.2956 40.5 31.5 40.5ZM16.5 37.5H31.5C33.0913 37.5 34.6174 38.1321 35.7426 39.2574C36.8679 40.3826 37.5 41.9087 37.5 43.5V46.5C37.5 46.8978 37.342 47.2794 37.0607 47.5607C36.7794 47.842 36.3978 48 36 48H12C11.6022 48 11.2206 47.842 10.9393 47.5607C10.658 47.2794 10.5 46.8978 10.5 46.5V43.5C10.5 41.9087 11.1321 40.3826 12.2574 39.2574C13.3826 38.1321 14.9087 37.5 16.5 37.5Z" fill="#FF0009"/>
            <path d="M19.575 21C19.075 21 18.6833 20.9057 18.4 20.7171C18.1333 20.5173 18 20.2344 18 19.8682V17.4549C18 16.5895 18.1 15.785 18.3 15.0416C18.5167 14.2982 18.85 13.638 19.3 13.061C19.7667 12.4841 20.3583 12.018 21.075 11.663C21.8083 11.2968 22.6917 11.0693 23.725 10.9806L23.25 11.38V9.86547C23.25 9.58807 23.3583 9.37725 23.575 9.23301C23.7917 9.07767 24.1 9 24.5 9C24.9 9 25.2083 9.07767 25.425 9.23301C25.6417 9.37725 25.75 9.58807 25.75 9.86547V11.38L25.3 10.9806C26.3333 11.0693 27.2083 11.2968 27.925 11.663C28.6583 12.018 29.25 12.4841 29.7 13.061C30.15 13.638 30.475 14.2982 30.675 15.0416C30.8917 15.785 31 16.5895 31 17.4549V19.8682C31 20.2344 30.8667 20.5173 30.6 20.7171C30.3333 20.9057 29.95 21 29.45 21C28.9333 21 28.5417 20.9057 28.275 20.7171C28.0083 20.5173 27.875 20.2344 27.875 19.8682V17.4549C27.875 16.4341 27.7917 15.6075 27.625 14.975C27.4583 14.3315 27.1833 13.8433 26.8 13.5104C26.4167 13.1664 25.9083 12.9445 25.275 12.8447L25.75 12.7614V17.4716C25.75 17.749 25.6417 17.9653 25.425 18.1207C25.2083 18.2649 24.9 18.337 24.5 18.337C24.1 18.337 23.7917 18.2649 23.575 18.1207C23.3583 17.9653 23.25 17.749 23.25 17.4716V12.7614L23.725 12.8447C23.1083 12.9445 22.6083 13.1664 22.225 13.5104C21.8417 13.8544 21.5583 14.3481 21.375 14.9917C21.2083 15.6241 21.125 16.4452 21.125 17.4549V19.8682C21.125 20.2344 20.9917 20.5173 20.725 20.7171C20.4583 20.9057 20.075 21 19.575 21Z" fill="#FF0009"/>
        </g>
        <defs>
            <clipPath id="clip0_1894_2254">
                <rect width="48" height="48" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)

export default Cup