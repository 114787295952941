import {
  Crocusoft,
  Email,
  Facebook,
  Instagram,
  LegalAge,
  OneXTwo,
  Phone,
  Tiktok,
  Topaz,
  UniqueSportsBetting,
  Whatsapp,
} from 'components/icons';
import { Link } from 'react-router-dom';

import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__logo">
          <Topaz />
          <OneXTwo />
        </div>
        <div className="footer__links">
          <p className="footer__text">Keçidlər</p>
          <ul>
            <li>
              <Link to="/" className="footer__text--gray">
                Ana səhifə
              </Link>
            </li>
            <li>
              <Link to="/bron" className="footer__text--gray">
                Bron et
              </Link>
            </li>
            <li>
              <Link to="/circulations" className="footer__text--gray">
                Turlar
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer__contact">
          <p className="footer__text">Əlaqə</p>
          <ul>
            <li>
              <a href="tel: 926" className="footer__text--gray">
                <Phone />
                926
              </a>
            </li>
            <li>
              <a href="mailto: help@topaz.az" className="footer__text--gray">
                <Email />
                help@topaz.az
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/message/KOSIJPWC4W3LB1"
                rel="noreferrer"
                target="_blank"
                className="footer__text--gray"
              >
                <Whatsapp />
              </a>
              <a
                href="https://www.tiktok.com/@topaz.resmi?_t=8kHrBa0KvGU&_r=1"
                rel="noreferrer"
                target="_blank"
                className="footer__text--gray"
              >
                <Tiktok />
              </a>
              <a
                href="https://www.instagram.com/topazresmi/"
                rel="noreferrer"
                target="_blank"
                className="footer__text--gray"
              >
                <Instagram />
              </a>
              <a
                href="https://www.facebook.com/Topazresmi"
                rel="noreferrer"
                target="_blank"
                className="footer__text--gray"
              >
                <Facebook />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__legal-age">
          <div className="footer__legal-age-inner">
            <LegalAge />
            <p>
              18 yaşından kiçik şəxslərin idman mərc oyunlarında iştirak etməsi
              qadağandır!
            </p>
          </div>
          <div className="footer__legal-age-bottom">
            <p>Developed by</p>
            <div className="footer__legal-age-bottom-flex">
              <a href="https://crocusoft.com" target="_blank" rel="noreferrer">
                <Crocusoft className="footer__crocusoft" />
              </a>
              <a href="https://www.usb1x2.com" target="_blank" rel="noreferrer">
                <UniqueSportsBetting
                  classNames={{
                    white: 'footer__usb-white',
                    black: 'footer__usb-black',
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <p>&copy; Topaz – All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
