import './CirculationCard.css';

const staticImages = {
    'TOP 10': '/media/top-10-loading.png',
    '12 DUEL': '/media/duel-loading.png',
    'Şanslı 13': '/media/lucky-13-loading.png',
    'SUPER 15': '/media/super-15-loading.jpg'
};

const UnplayableCard = ({ data }) => {
    return (
        <div className="circulation-card">
            <div className="circulation-card__image">
                <img
                    src={staticImages[data.name] ?? '/media/lucky-13-loading.png'}
                    alt="magic-balls"
                />
                <span className="circulation-card__game-count">
          {data.circulationGames?.length}
        </span>
            </div>
            <div className="circulation-card__date">
                <div style={{height: "20px"}}></div>
            </div>
            <div className="circulation-card__caption">
                <div className="circulation-card__informations">
                    <div className="circulation-card__information">
                        <span>Cekpot məbləği</span>
                        <span>---- AZN</span>
                    </div>
                    <div className="circulation-card__information">
                        <span>Hovuz məbləği</span>
                        <span>---- AZN</span>
                    </div>
                </div>
                <div className="circulation-card__buttons">
                    <button className="circulation-card__link">
                        Hazırlanır
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UnplayableCard;
