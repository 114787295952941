import React from 'react';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {useLocation} from "react-router-dom";

import {ArrowLeft, ArrowRight} from '../../../components/icons';

import '../styles/style.css';

export default function CustomPagination({
                                             totalCount,
                                             onChange
                                         }) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const skip = params.get("skip");
    const take = params.get("take");
    const pageNumber = skip > 0 ? (skip / take) + 1 : 1;

    const pageCount = Math.ceil(totalCount / take);

    const handleChange = (event, value) => {
        const skip = (value - 1) * take;
        console.log(skip)
        onChange(skip);
    };

    return (
        <div className="custom-pagination">
            <Pagination
                count={pageCount}
                page={pageNumber}
                onChange={handleChange}
                renderItem={(item) => (
                    <PaginationItem
                        components={{previous: ArrowLeft, next: ArrowRight}}
                        {...item}
                    />
                )}
            />
        </div>
    );
}
