import React from "react";

import Footer from "../footer";
import Header from "../header";

const HomeLayout = ({children}) => (
    <>
        <Header isHome/>
        {children}
        <Footer/>
    </>
)

export default HomeLayout
