import * as React from 'react';

const SvgUserIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 2.333a5.547 5.547 0 0 0-5.542 5.542c0 2.998 2.345 5.425 5.402 5.53a.938.938 0 0 1 .256 0H14.198a5.528 5.528 0 0 0 5.343-5.53A5.547 5.547 0 0 0 14 2.333ZM19.927 16.508c-3.255-2.17-8.563-2.17-11.842 0-1.481.992-2.298 2.334-2.298 3.769s.817 2.765 2.287 3.745c1.633 1.096 3.78 1.645 5.926 1.645 2.147 0 4.294-.549 5.927-1.645 1.47-.992 2.287-2.322 2.287-3.769-.012-1.435-.817-2.765-2.287-3.745Z"
      fill="#24A3FF"
    />
  </svg>
);

export default SvgUserIcon;
