import React from "react";

import {Filter} from "components/icons";

import "./FilterButton.css";
const FilterButton = ({onClick}) => {
    return (
        <button onClick={onClick} className="filter-button">
            <Filter/>
            <span>Filter</span>
        </button>
    )
}

export default FilterButton