import React, {useState} from "react";

import AccordionItem from "./AccordionItem";

import "./Accordion.css";

const faqData = [
    {
        id: 1,
        title: "1X2 nədir?",
        description: "1X2 Topazın dinamik yeni məhsuludur. 1X2 məhsulu oyun nəticələrini düzgün təxmin edərək cekpot və ya hovuz məbləğini qazanmaq mübarizəsidir.",
        open: false
    },
    {
        id: 2,
        title: "Tur nədir?",
        description: "1X2 oyununda hər bir məhsul həftəlik olaraq tur şəklində oynanılır. Tur öncədən təyin olunmuş tarix aralığında oynanılan oyunlar üçün mərcin keçirilmə formasıdır.",
        open: false
    },
    {
        id: 3,
        title: "Topaz 1X2 necə oynanılır?",
        description: "Siz www.topaz1x2.az saytında  aktiv olan turları görə və hesab yaradaraq müvafiq tura daxil olan oyunları seçə bilərsiniz. Əgər ev sahibi komandanın qazanacağına inanırsınızsa 1 qutusunu işarələyin. Əgər oyunun heç-heçə bitəcəyini düşünürsünüzsə X qutusunu, yox qonaq komanda qazanacaq deyirsinizsə  2 qutusunu işarələyin. Bron edilmiş kupon ilə agentə yaxınlaşaraq barkodu oxutdurduqda bilet yaranır və mərc məbləğini ödəyərək mərc oyununda iştirak etmiş olursunuz.",
        open: false
    },
    {
        id: 4,
        title: "Cekpot məbləği nədir?",
        description: "Əgər siz turda verilən bütün oyunları düz təxmin etsəniz, tur üçün öncədən təyin olunmuş böyük  mükafatı qazanacaqsınız. Bir neçə oyunçu bütün oyunları düz təxmin edərsə, cekpot məbləği oyunçular arasında bərabər bölüşdürüləcək. Mükafatın məbləği bir turda qazanılmadığı halda, növbəti turda oynanılır və qazanılanadək növbəti turlara ötürələcəkdir.",
        open: false
    },
    {
        id: 5,
        title: "1 oyun təxirə gedərsə Cekpot qazanılırmı?",
        description: "Xeyr, əgər 13 oyundan 12-ni bilmisinizsə və 1 oyun təxirə gedibsə bu zaman Cekpot məbləği qazanılmır.",
        open: false
    },
    {
        id: 6,
        title: "Hovuz məbləği nədir?",
        description: "Oyunçular tərəfindən edilən mərclərə əsasən yığılan uduş məbləğidir. Turun nəticələrinə görə ən çox düzgün oyun proqnoz etmiş iştirakçı qalib sayılır və hovuz məbləğini qazanır. Eyni anda ən çox bərabər sayda düzgün proqnoz tapan oyunçular arasında bölünür.",
        open: false
    },
    {
        id: 7,
        title: "Minimum mərc məbləği nə qədərdir?",
        description: "Minimum mərc məbləği şərti olaraq 0.50 AZN-dir. Minimum mərc məbləği məhsula görə dəyişə bilər. Bundan əlavə, heç bir məhdudiyyət qoyulmadan siz istənilən oyunda həm üfüqi, həm də şaquli istiqamətdə bütün sütunları doldura bilərsiniz. Bu zaman mərc məbləği müvafiq olaraq dəyişəcəkdir.",
        open: false
    },
    {
        id: 8,
        title: "Maximum uduş məbləği?",
        description: "Maximum uduş məbləği hovuza yığılan məbləğə bərabərdir. Cekpot məbləği bura daxil deyildir. Cekpot məbləği Topaz tərəfindən təqdim olunur.",
        open: false
    },
    {
        id: 9,
        title: "Çoxvariantlı mərc nədir?",
        description: "Siz bir oyun üçün bir neçə xananı işarələyərək çoxvariantlı mərc edə bilərsiniz.",
        open: false
    },
    {
        id: 10,
        title: "Uduş necə əldə olunur?",
        description: "Topaz məntəqəsinə yaxınlaşaraq fiziki bileti satıcıya təqdim edərək uduş mükafatını əldə edə bilərsiniz. 3000 AZN-dən yuxarı ödənişlərdə şəxsiyyət vəsiqəsi və uduşlu fiziki biletlə birlikdə Topaz baş ofisinə gələrək uduş mükafatını alırsınız.",
        open: false
    },
    {
        id: 11,
        title: 'Misli nədir?',
        description: "Əgər siz bir neçə qazananlar siyahısında öz uduş məbləğinizi artırmaq istəyirsinizsə, 1-10 aralığnda misli seçərək öz hovuz və ya cekpot qazancınızı artıra bilərsiniz. Necə? Hovuz və ya Cekpot məbləği birdən çox oyunçu tərəfindən qazanıldığı halda, uduş məbləği misli saylarının cəminə bölünərək hər oyunçuya müvafiq misli ilə təqdim olunur. Məsələn, 10 000AZN hovuz məbləği üçün siz 2X seçərək, başqa bir oyunçu 1X, daha başqa bir oyunçu isə 1X seçərək qalib olmuşdur. Bu zaman 10 000 AZN 4-ə(2X+1X+1X) bölünür və uduş hər oyunçunun misli nisbətinə vurularaq təqdim edilir. Yəni bu halda siz (10 000/4)*2= 5000AZN, digər 1X misli seçən oyunçuların hər biri isə (10 000/4)*1= 2 500AZN qazanır. Qeyd: Bu zaman mərc məbləği də seçilən misli nisbətinə vurularaq hesablanır."
    }
];

const Accordion = () => {
    const [data, setData] = useState(faqData);

    const handleClick = (index) => () => {
        setData(prevState => prevState.map(((item, idx) => {
            if (index === idx) {
                return {...item, open: !item.open}
            }
            return {...item, open: false}
        })))
    }

    return (
        <div className="accordion">
            {data.map((item, index) => (
                <AccordionItem
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    onClick={handleClick(index)}
                    open={item.open}
                />
            ))}
        </div>
    )
}

export default Accordion