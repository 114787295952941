import { CircularProgress, styled } from '@mui/material';

// import { styled } from "@material-ui/styles/index";

function Spinner({ color }) {
  const SyledCircularProgress = styled((props) => (
    <CircularProgress {...props} />
  ))({
    width: 'unset !important',
    height: 'unset !important',
    color: `${color ? color : '#ffffff'} `,
    '& .MuiCircularProgress-svg': {
      width: '17px',
      height: '17px',
    },
  });

  return <SyledCircularProgress />;
}

export default Spinner;
