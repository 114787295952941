import RenderIf from 'components/RenderIf';
import { Link } from 'react-router-dom';

import './CirculationCard.css';
import CountDown from './CountDown';

const staticImages = {
  'TOP 10': '/media/top-10.png',
  '12 DUEL': '/media/duel.png',
  'Şanslı 13': '/media/lucky-13.png',
  'SUPER 15': '/media/super-15.jpg'
};

const CirculationCard = ({ data }) => {
  return (
    <div className="circulation-card">
      <div className="circulation-card__image">
        <img
          src={staticImages[data.productName] ?? '/media/lucky-13.png'}
          alt="magic-balls"
        />
        <span className="circulation-card__game-count">
          {data.circulationGames?.length}
        </span>
      </div>
      <div className="circulation-card__date">
        <span>
          <CountDown targetDate={data.expiredDate} />
        </span>
      </div>
      <div className="circulation-card__caption">
        <div className="circulation-card__informations">
          <RenderIf condition={data.isJackpot}>
            <div className="circulation-card__information">
              <span>Cekpot məbləği</span>
              <span>{data?.circulationJackpot?.amount} AZN</span>
            </div>
          </RenderIf>
          <div className="circulation-card__information">
            <span>Hovuz məbləği</span>
            <span
              className={
                !data.possibleSeeTotalAmount
                  ? 'circulation-card__information-blur'
                  : ''
              }
            >
              {data?.totalAmount} AZN
            </span>
          </div>
        </div>
        <div className="circulation-card__buttons">
          <Link
            to={`/bron?circulationId=${data.id}`}
            className="circulation-card__link"
          >
            İndi oyna
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CirculationCard;
