import { reducer } from 'modules/home/_redux/PlayTicketsRedux';
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import * as auth from '../modules/auth/_redux/authRedux';
// import * as playTickets from '../modules/home/_redux/PlayTicketsRedux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  playTickets: reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
