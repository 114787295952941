import { useState } from 'react';

import { useAuth } from 'hooks';
import { Link } from 'react-router-dom';

import UserProfileDropdown from '../../modules/home/components/UserProfileDropdown';
import ActiveLink from '../ActiveLink';
import { MenuIcon, OneXTwo } from '../icons';
import MobileMenu from '../mobile-menu';
import RenderIf from '../RenderIf';

import './Header.css';

const Header = ({ isHome }) => {
  const isAuth = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className={`header ${isHome ? 'position-absolute' : ''}`}>
      <div className="container header__container">
        <button
          onClick={() => setIsMenuOpen(true)}
          className="header__mobile-menu"
        >
          <MenuIcon />
        </button>
        <Link to="/" className="header__logo">
          <OneXTwo isHome={isHome} />
        </Link>
        <div className="header__list">
          <ActiveLink
            to="/"
            className="header__list-item"
            activeClassName="header__list-item--active"
          >
            Ana səhifə
          </ActiveLink>
          <ActiveLink
            to="/bron"
            className="header__list-item"
            activeClassName="header__list-item--active"
          >
            Bron et
          </ActiveLink>
          <ActiveLink
            to="/circulations"
            className="header__list-item"
            activeClassName="header__list-item--active"
          >
            Turlar
          </ActiveLink>
          <ActiveLink
            to="/results"
            className="header__list-item"
            activeClassName="header__list-item--active"
          >
            Nəticələr
          </ActiveLink>
          <RenderIf condition={isAuth}>
            <ActiveLink
              to="/my-tickets"
              className="header__list-item"
              activeClassName="header__list-item--active"
            >
              Biletlərim
            </ActiveLink>
            <ActiveLink
              to="/my-bron-tickets"
              className="header__list-item"
              activeClassName="header__list-item--active"
            >
              Bron edilmiş kuponlarım
            </ActiveLink>
          </RenderIf>
        </div>
        {isAuth ? (
          <div className="ms-auto">
            <UserProfileDropdown />
          </div>
        ) : (
          <div className="header__buttons">
            <Link
              to="/auth/login"
              className="header__button header__button--outline"
            >
              Daxil ol
            </Link>
            <Link
              to="/auth/register"
              className="header__button header__button--primary"
            >
              Qeydiyyat
            </Link>
          </div>
        )}
      </div>
      <MobileMenu open={isMenuOpen} onHide={setIsMenuOpen} />
    </header>
  );
};

export default Header;
