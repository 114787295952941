import {useEffect, useState} from 'react';

import {Prize, SackMoney} from 'components/icons';

import {getCirculations, getProducts} from '../../requests';
import Card from '../circulation-card';
import './Circulations.css';
import UnplayableCard from "../circulation-card/UnplayableCard";

const staticImages = {
    'TOP 10': '/media/top-10-loading.png',
    '12 DUEL': '/media/12-duel-loading.png',
    'Şanslı 13': '/media/lucky-13-loading.png',
    'SUPER 15': '/media/super-15.jpg'
};

const Circulations = ({circulationData}) => {
    const [circulations, setCirculations] = useState([]);
    const [unPlayableProducts, setUnPlayableProducts] = useState([]);

    useEffect(() => {
        getCirculations()
            .then((res) => {
                setCirculations(res.data?.data ?? []);
                getProducts()
                    .then((productsRes) => {
                        setUnPlayableProducts(productsRes.data?.data?.filter(product => !res.data?.data?.some(circulation => circulation.productName === product.name) && product.id !== 4) ?? []);
                    })
                    .catch(() => null);
            })
            .catch(() => null);


        const interval = setInterval(() => {
            getCirculations()
                .then((res) => {
                    setCirculations(res.data?.data ?? []);
                })
                .catch(() => null);
        }, 600000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div className="home-circulations">
            <div className="container">
                <p className="home-circulations__title">Turlar</p>
                <div className="home-circulations__container">
                    {circulations.map((circulation) => (
                        <Card key={circulation.id} data={circulation}/>
                    ))}
                    {unPlayableProducts.filter(product => Boolean(staticImages[product.name])).map(product => (
                        <UnplayableCard key={product.id} data={product}/>
                    ))}
                </div>
                <div className="container home-circulations__details">
                    <div className="home-circulations__detail">
                        <SackMoney/>
                        <p>₼ {circulationData?.maxWinAmount}</p>
                        <span>Maksimal uduş məbləği</span>
                    </div>
                    <div className="home-circulations__detail">
                        <Prize/>
                        <p>₼ {circulationData?.jackpotAmount}</p>
                        <span>Cekpot məbləği</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Circulations;
