import * as React from 'react';

import Box from '@mui/material/Box';
import { azAZ } from '@mui/material/locale';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import AlertComponent from 'components/alert';
import { format } from 'date-fns';
import { AiOutlineEye } from 'react-icons/ai';
import {createUseStyles} from 'react-jss'
import './styles/style.css';

const theme = createTheme(azAZ);

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(2n)': {
    backgroundColor: '#EAF6FF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Bilet ID',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'winningAmount',
    numeric: true,
    disablePadding: false,
    label: 'Uduş məbləği',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: 'Uduş statusu',
  },
  {
    id: 'createdDate',
    label: 'Tarix',
  },
  {
    id: '',
    label: 'Əməliyyatlar',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, sortField } = props;

  const useStyles = createUseStyles({
    activeHeadCell: {
      borderBottom: '1px solid #24A3FF !important',
      color: '#24A3FF !important',
    },
    actioveDirectionRow: {
      color: '#24A3FF !important',
    },
    TableRow: {
      padding: '0 35px !important',
    },
  });

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.TableRow}>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align="left"
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy ? 'dsc' : 'asc'}
                onClick={() => {
                  onRequestSort(headCell.id);
                }}
                className={
                  sortField === headCell.id
                    ? `${classes.activeHeadCell} activeHeadCell`
                    : null
                }
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    className={classes.actioveDirectionRow}
                    component="span"
                    sx={visuallyHidden}
                  >
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  rows,
  loading,
  totalCount,
  take,
  orderBy,
  sortField,
  setSkip,
  setTake,
  setOrderBy,
  setSortField,
  setShowTicket,
  setTicketData,
}) {
  const [order] = React.useState('asc');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (field) => {
    const isOrder = field === sortField && !orderBy;

    setSortField(field);
    setOrderBy(isOrder);
    setTake(10);
    setSkip(0);
    setPage(0);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    setSkip(newPage * take);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setTake(+event.target.value);
    setPage(0);
    setSkip(0);
  }

  const useStyles = createUseStyles({
    TableCell: {
      padding: '14px 35px !important',
    },
    blue: {
      color: '#24A3FF',
    },
    red: {
      color: '#FF0009',
    },
    gray: {
      color: '#999999',
    },
    yellow: {
      color: '#ff9b0f',
    },
    purple: {
      color: '#8704de',
    },
    button: {
      background: 'unset',
      border: 'unset',
    },
    svg: {
      width: '20px',
      height: '20px',
      color: '#0996ed',
    },
  });

  const classes = useStyles();

  return (
    <Box sx={{ width: '100%' }} className="custom-table">
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          {rows.length ? (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                sortField={sortField}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow tabIndex={-1} key={index}>
                      <TableCell align="left" className={classes.TableCell}>
                        {row.id}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.customerNumber ?? row.agentNumber}
                      </TableCell>
                      <TableCell padding="none" align="left">
                        {row.winningAmount && row.statusId !== 3 ? (
                          `${row.winningAmount} AZN`
                        ) : (
                          <div className="ps-5">-</div>
                        )}
                      </TableCell>
                      <TableCell padding="none" align="left">
                        <span
                          className={
                            row.statusId === 0
                              ? classes.gray
                              : row.statusId === 2
                              ? classes.blue
                              : row.statusId === 3
                              ? classes.red
                              : row.statusId === 4
                              ? classes.yellow
                              : row.statusId === 5
                              ? classes.purple
                              : ''
                          }
                        >
                          {row.statusId === 0
                            ? 'Gözləmədə'
                            : row.statusId === 2
                            ? 'Uduşlu'
                            : row.statusId === 3
                            ? 'Uduşsuz'
                            : row.statusId === 4
                            ? 'Ləğv edilib'
                            : row.statusId === 5 && 'Tur ləğv edilib'}
                        </span>
                      </TableCell>
                      <TableCell padding="none" align="left">
                        {format(new Date(row.createdDate), 'dd.MM.yyyy HH:mm')}
                      </TableCell>
                      <TableCell padding="none" align="left">
                        <button
                          className={classes.button}
                          onClick={() => {
                            setShowTicket(true);
                            setTicketData(row);
                          }}
                        >
                          Biletə bax <AiOutlineEye className={classes.svg} />
                        </button>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            !rows.length &&
            !loading && (
              <AlertComponent message="Hal-hazırda oynanılmış bilet yoxdur." />
            )
          )}
        </TableContainer>
        {totalCount > 10 && (
          <ThemeProvider theme={theme}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        )}
      </Paper>
    </Box>
  );
}
