import React, { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InfoCircle } from 'components/icons';
import Input from 'components/input/Input';
import RenderIf from 'components/RenderIf';
import { useFormik } from 'formik';
import { changePassword } from 'modules/auth/_redux/authCrud';
import { actions } from 'modules/auth/_redux/authRedux';
import { Button, Modal } from 'react-bootstrap';
import {createUseStyles} from 'react-jss'
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const useStyles = createUseStyles({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    justifyContent: 'space-between',
    padding: '10px',
  },
  input: {
    '&:focus': {
      border: '1px solid #ff0009',
    },
    border: '1px solid #999999',
    borderRadius: '8px',
    outline: 'unset',
    padding: ' 5px 16px',
    height: '40px',
  },
  pinInput: {
    width: '50px',
    height: '50px',
    '&:focus': {
      border: '1px solid #ff0009',
    },
    border: '1px solid #999999',
    borderRadius: '8px',
    outline: 'unset',
    padding: ' 5px 16px',
    textAlign: 'center',
  },
  label: {
    marginLeft: '20px',
  },
});

export default function ChangePasswordModal({
  show,
  onHide,
  phoneNumber,
  errorStatus,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const togglePasswordVisibilityTwo = () => {
    setShowPasswordTwo(!showPasswordTwo);
  };
  const classes = useStyles();
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const { login } = actions;

  const formik = useFormik({
    initialValues: {
      otp: '',
      password: '',
      passwordCheck: '',
    },
    validationSchema: Yup.object({
      otp: Yup.string().min(6).max(6).required('OTP kod tələb olunur'),
      password: Yup.string()
        .required('Yeni şifrəni daxil edin')
        .min(8, 'Ən az 8 xarakter olmalıdır')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
          'Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.'
        ),
      passwordCheck: Yup.string()
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
        //   'Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.'
        // )
        .oneOf(
          [Yup.ref('password'), null],
          'Yeni şifrəni təkrar düzgün daxil edin'
        ),
    }),
    onSubmit: (values) => {
      changePassword(values.otp, phoneNumber, values.password)
        .then(({ data: { accessToken, refreshToken, expireDate } }) => {
          dispatch(login(accessToken, refreshToken, expireDate));
          onHide(false);
        })
        .catch((err) => {
          const message = err.response?.data?.message ?? 'Xəta baş verdi';
          setError(message);
        });
    },
  });

  function handleClose() {
    onHide(false);
  }


  return (
    <React.Fragment>
      <Modal centered show={show} onHide={onHide}>
        <RenderIf condition={errorStatus}>
          <h1 style={{ padding: '50px' }} className="text-center text-danger">
            {errorStatus}
          </h1>
        </RenderIf>
        <RenderIf condition={!errorStatus}>
          <form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Şifrəni dəyiş</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={classes.inputContainer}>
                <label>OTP kodu:</label>
                <input
                  className={classes.input}
                  name="otp"
                  onChange={formik.handleChange}
                  maxLength="6"
                />
                <div className="d-flex align-items-center gap-2 mt-2">
                  <InfoCircle />{' '}
                  <span className="fw-light">
                    Kod telefonunuza mesaj olaraq göndərilmişdir
                  </span>
                </div>
              </div>

              <div className={`${classes.inputContainer} `}>
                <label>Yeni şifrə:</label>
                <div className="showButton">
                  <Input
                    style={{ border: '1px solid black' }}
                    placeholder="Yeni şifrə"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    //   onKeyPress={handleKeyPress}
                    //   maxLength="5"
                    className="w-100"
                  />
                  <div className="showButton-eye">
                    <span
                      className={`password-toggle-icon ${
                        showPassword ? 'visible' : 'hidden'
                      }`}
                    >
                      <RenderIf condition={showPassword}>
                        <VisibilityOff
                          role="button"
                          onClick={togglePasswordVisibility}
                        />
                      </RenderIf>
                      <RenderIf condition={!showPassword}>
                        <Visibility
                          role="button"
                          onClick={togglePasswordVisibility}
                        />
                      </RenderIf>
                    </span>
                  </div>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container my-2">
                    <div className="fv-help-block  text-danger text-center">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className={classes.inputContainer}>
                <label>Yeni şifrə(təkrar):</label>
                <div className="showButton">
                  <Input
                    style={{ border: '1px solid black' }}
                    placeholder="Yeni şifrə(təkrar)"
                    name="passwordCheck"
                    type={showPasswordTwo ? 'text' : 'password'}
                    value={formik.values.passwordCheck}
                    onChange={formik.handleChange}
                    //   onKeyPress={handleKeyPress}
                    //   maxLength="5"
                    className="w-100"
                  />
                  <div className="showButton-eye">
                    <span
                      className={`password-toggle-icon ${
                        showPasswordTwo ? 'visible' : 'hidden'
                      }`}
                    >
                      <RenderIf condition={showPasswordTwo}>
                        <VisibilityOff
                          role="button"
                          onClick={togglePasswordVisibilityTwo}
                        />
                      </RenderIf>
                      <RenderIf condition={!showPasswordTwo}>
                        <Visibility
                          role="button"
                          onClick={togglePasswordVisibilityTwo}
                        />
                      </RenderIf>
                    </span>
                  </div>
                </div>
                {formik.touched.passwordCheck && formik.errors.passwordCheck ? (
                  <div className="fv-plugins-message-container my-2">
                    <div className="fv-help-block text-danger text-center">
                      {formik.errors.passwordCheck}
                    </div>
                  </div>
                ) : null}
              </div>
              <RenderIf condition={Boolean(error)}>
                <div className="d-flex justify-content-center ">
                  <span className="text-danger">{error}</span>
                </div>
              </RenderIf>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                İmtina
              </Button>
              <Button type="submit" variant="success">
                Yadda saxla
              </Button>
            </Modal.Footer>
          </form>
        </RenderIf>
      </Modal>
    </React.Fragment>
  );
}
