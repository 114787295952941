import React from "react";

import {Three, Verify, Cup, Transparency} from "components/icons";

import "./Description.css";

const Description = () => {
    return (
        <div className="home-description">
           <div className="container">
               <div className="home-description__container">
                   <div className="home-description__left">
                       <Three/>
                       <p>1x2-ə qoşulmaq üçün 3 səbəb</p>
                   </div>
                   <div className="home-description__right">
                       <div className="home-description__item">
                           <div className="home-description__icon-box">
                               <Cup/>
                           </div>
                           <span className="home-description__text">Böyük uduş fondu</span>
                       </div>
                       <div className="home-description__item">
                           <div className="home-description__icon-box">
                               <Verify/>
                           </div>
                           <span className="home-description__text">Güvənilirlik</span>
                       </div>
                       <div className="home-description__item">
                           <div className="home-description__icon-box">
                               <Transparency/>
                           </div>
                           <span className="home-description__text">Şəffaflıq</span>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    )
}

export default Description