import {useEffect} from "react";

const GTM = ({children}) => {

    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-HTXN8NX19Z';
        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-HTXN8NX19Z');
        }
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        }
    }, [])

    return children
}

export default GTM