import { useState, useEffect } from 'react';

import axios from 'axios';
import {useLocation} from "react-router-dom";

function useCirculationAll() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [skip, setSkip] = useState(0);
  const [take] = useState(6);
  const [totalCount, setTotalCount] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const getAllTimer = setTimeout(async function () {
      try {
        setLoading(true);
        const {
          data: { data, totalCount },
        } = await axios.get(
          `circulations/filter${location.search}`
        );
        setData(data);
        setTotalCount(totalCount);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }, 500);
    return () => clearTimeout(getAllTimer);
  }, [location.search]);

  return { data, error, loading, skip, setSkip, take, totalCount };
}

export default useCirculationAll;
