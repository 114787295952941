import { useState } from 'react';

import { Check, Close } from '@mui/icons-material';
import axios from 'axios';
import Rotate from "components/icons/Rotate";
import RenderIf from 'components/RenderIf';
import Spinner from 'components/Spinner';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { Badge, Button, Modal } from 'react-bootstrap';
import './CheckBilet.css';

const CheckBilet = () => {
  const [errMsg, setErrMsg] = useState('');
  const [data, setData] = useState({});
  const [show, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheck = (values) => {
    setIsLoading(true);
    axios
      .get(`result/searchByTicketNumber?ticketNumber=${values.bilet}`)
      .then((response) => {
        setIsShow(true);
        setData(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsShow(false);
        setErrMsg(error?.response?.data?.message || 'Bilet tapılmadı');
        setIsLoading(false);
      });
  };
  const inputOnChange = (event) => {
    formik.handleChange(event);
    setErrMsg(false);
  };
  const formik = useFormik({
    initialValues: {
      bilet: '',
    },
    onSubmit: (values) => {
      handleCheck(values);
    },
  });
  const STATUS_BUTTON = {
    Lost: { text: 'UDUŞSUZ', color: 'danger' },
    Cancel: { text: 'LƏĞV EDİLİB', color: 'warning' },
    Pending: { text: 'GÖZLƏYİR', color: 'info' },
    Win: { text: 'UDUŞLU', color: 'success' },
  };
  const statusText = STATUS_BUTTON[data?.statusName]?.text;
  const statusColor = STATUS_BUTTON[data?.statusName]?.color;
  return (
    <>
      <div className="checkBilet">
        <h1 className="text-danger">Bileti yoxla</h1>
        <form onSubmit={formik.handleSubmit}>
          <input
            type="text"
            name="bilet"
            id="bilet"
            placeholder="Bilet nömrəsi"
            onChange={inputOnChange}
          />

          <div className="button-custom">
            <button
              disabled={formik.values.bilet.length < 1 || isLoading}
              type="submit"
            >
              {isLoading ? <Spinner /> : 'Axtar'}
            </button>
          </div>
        </form>
        <RenderIf condition={Boolean(errMsg)}>
          <p className="error-text my-4 text-center">{errMsg}</p>
        </RenderIf>
      </div>
      <Modal centered size="md" show={show} onHide={() => setIsShow(false)}>
        <Modal.Header closeButton>
          <h1 style={{ fontSize: '22px' }}>Bilet məlumatları</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-6 pr-6 pt-6">
            <div className="d-flex justify-content-center">
              <img
                width="300"
                height="100"
                src="/media/1x2-logo.svg"
                alt="1x2"
                className="logo"
              />
            </div>
            <div className="divider"></div>
            <div className="row px-3">
              <div className="w-100 pt-2 pb-1 pt-2 d-flex justify-content-center ">
                <Badge
                  className="py-2 px-4"
                  style={{ fontSize: '20px' }}
                  bg={statusColor}
                >
                  {statusText}
                </Badge>
              </div>
              <div className="col-12 col-md-12 col-lg-12 p-0">
                <div className="w-100 pt-1 pb-1 pt-1 d-flex justify-content-between flex-wrap">
                  <span className="text">Tur nömrəsi:</span>
                  <span className="text">{data?.circulationNumber ?? '-'}</span>
                </div>
                <div className="w-100 pt-1 pb-1 pt-1 d-flex justify-content-between flex-wrap">
                  <span className="text">Agent Id:</span>
                  <span className="text">{data?.agentNumber ?? '-'}</span>
                </div>
                <div className="w-100 pt-1 pb-1 pt-1 d-flex justify-content-between flex-wrap">
                  <span className="text">Bilet qiyməti:</span>
                  <span className="text">{data?.price ?? '-'}</span>
                </div>
                <div className="w-100 pt-1 pb-1 pt-1 d-flex justify-content-between flex-wrap">
                  <span className="text">Məhsulun adı:</span>
                  <span className="text">{data?.productName ?? '-'}</span>
                </div>
                <div className="w-100 pt-1 pb-1 pt-1 d-flex justify-content-between flex-wrap">
                  <span className="text">Telefon nömrəsi:</span>
                  <span className="text">{data?.customerNumber ?? '-'}</span>
                </div>
                <div className="w-100 pt-1 pb-1 pt-1 d-flex justify-content-between flex-wrap">
                  <span className="text">Düzgün təxmin:</span>
                  <span className="text">{data?.correctCount ?? '-'}</span>
                </div>
                {data?.isJackpot && (
                  <div className="w-100 pt-1 pb-1 pt-1 d-flex justify-content-between flex-wrap">
                    <span className="text">Cekpot məbləği:</span>
                    <span className="text">
                      {data?.jackpotAmount ?? '-'} AZN
                    </span>
                  </div>
                )}

                <div className="w-100 pt-1 pb-1 pt-1 d-flex justify-content-between flex-wrap">
                  <span className="text">Yaradılma tarixi:</span>
                  <span className="text">
                    {data?.createdDate &&
                      format(
                        new Date(data?.createdDate),
                        'dd.MM.yyyy HH:mm:ss'
                      )}
                  </span>
                </div>
              </div>

              <div className="divider"></div>
              <div className="games">
                {data?.ticketGames?.map((game, index) => (
                  <div className="game mb-2" key={index}>
                    <div className="gameTitle">
                      <div style={{ lineHeight: '21px' }}>
                        {index + 1}. {game.hostClub} - {game.guestClub}
                      </div>
                      <RenderIf condition={data?.statusName === 'Pending'}>
                        <div className="startDate">
                          {format(new Date(game.startDate), 'dd/MM/yy HH:mm')}
                        </div>
                      </RenderIf>
                      <RenderIf condition={data?.statusName !== 'Pending'}>
                        <div className="startDate">
                          <span>{game?.score} </span>
                          <span className="ms-3">
                            {game?.isCorrect && game.gameOptionId !== 4 ? (
                              <Check
                                sx={{
                                  color: 'green',
                                  width: '24px',
                                  height: '24px',
                                }}
                              />
                            ) : !game?.isCorrect && game.gameOptionId !== 4 ? (
                              <Close
                                sx={{
                                  color: 'red',
                                  width: '24px',
                                  height: '24px',
                                }}
                              />
                            ) : (
                                <Rotate width={24} height={18}/>
                            )}
                          </span>
                        </div>
                      </RenderIf>
                    </div>
                    <div className="gameDescription">
                      <span>
                        proqnoz: {game.options.option1 ? '1' : ''}
                        {game.options.optionX ? 'X' : ''}
                        {game.options.option2 ? '2' : ''}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsShow(false)}>Bağla</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CheckBilet;
