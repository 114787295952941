import React from "react";

import LottiePlayer from "../lottie-player";
import ballAnimation from "../lottie-player/animations/ball-animation.json";
import "./Loading.css";

const Loading = () => {
    return(
        <LottiePlayer
            animation={ballAnimation}
            width="200px"
            height="200px"
        />
    )
}

export default Loading