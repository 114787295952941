import React from 'react';

import axios from 'axios';
import {createRoot} from "react-dom/client";

import App from './App';
import * as redux from './redux';
import store, {persist} from './redux/store';
import 'reset-css';
import './styles/global.css';

redux.setupAxios(axios, store);

const app = document.getElementById('root');
const root = createRoot(app);
root.render(
    <React.StrictMode>
        <App store={store} persistor={persist}/>
    </React.StrictMode>
);
