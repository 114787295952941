import { Fragment, useEffect, useState } from 'react';

import axios from 'axios';
import { Back } from 'components/icons';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import RenderIf from '../../../components/RenderIf';
import { paramsObjectToString } from '../../../helpers';
import CustomProgress from '../../auth/components/CustomProgress';
import TableInner from '../../home/components/TableInner';
import ShowTicket from '../../home/modals/ShowTicket';
import './CirculationTickets.css';

const CirculationTickets = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showTicket, setShowTicket] = useState(false);
  const [ticketData, setTicketData] = useState([]);

  const [circulationId, setCirculationId] = useState('');
  const [canBePlayed, setCanBePlayed] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [orderBy, setOrderBy] = useState(false);
  const [sortField, setSortField] = useState('createdDate');

  const productName = data?.[0]?.productName;
  const circulationNumber = data?.[0]?.circulationNumber;

  useEffect(() => {
    const getTicketList = (filter = {}) => {
      const query = paramsObjectToString(filter);

      setLoading(true);
      axios
        .get(`tickets/getTicketList?${query}`)
        .then(({ data: { data, totalCount, canBePlayed } }) => {
          setData(data);
          setCirculationId(data[0]?.circulationId);
          setCanBePlayed(canBePlayed);
          setTotalCount(totalCount);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };

    getTicketList({
      circulationId: id,
      skip,
      take,
      orderBy,
      sortField,
      status: 2,
    });
  }, [id, skip, take, orderBy, sortField]);

  return (
    <Fragment>
      <Helmet>
        <title>Biletlər | 1x2</title>
      </Helmet>
      <div className="bg-gray py-4">
        <div className="circulation-tickets container">
          <div className="circulation-tickets__header">
            <button onClick={() => navigate(-1)}>
              <Back />
            </button>
            <div className="circulation-tickets__titles">
              <div className="circulation-tickets__title">
                <span>Məhsul adı:</span>
                <span>{productName}</span>
              </div>
              <div className="circulation-tickets__title">
                <span>Tur nömrəsi:</span>
                <span>{circulationNumber}</span>
              </div>
              {/* <div className="circulation-tickets__title">
                <label className="custom-select__label">Kupon statusu</label>
                <Select
                  // options={pendingStatusOptions}
                  defaultValue={{ label: 'Hamısı' }}
                  // value={pendingStatus}
                  // onChange={pendingStatustHandler}
                />
              </div> */}
            </div>
          </div>

          <TableInner
            rows={data}
            loading={loading}
            totalCount={totalCount}
            skip={skip}
            setSkip={setSkip}
            take={take}
            setTake={setTake}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            sortField={sortField}
            setSortField={setSortField}
            setShowTicket={setShowTicket}
            setTicketData={setTicketData}
          />
        </div>
        {showTicket && (
          <ShowTicket
            show={showTicket}
            onHide={setShowTicket}
            ticketData={ticketData}
            circulationId={circulationId}
            canBePlayed={canBePlayed}
          />
        )}
        <RenderIf condition={loading}>
          <CustomProgress />
        </RenderIf>
        <ToastContainer />
      </div>
    </Fragment>
  );
};

export default CirculationTickets;
