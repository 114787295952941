import React from 'react';

import {OneXTwo} from "components/icons";
import {Outlet} from 'react-router-dom';
import './styles/style.css';

const year = new Date().getFullYear();

export default function Layout() {
    return (
        <div className="auth">
            <div className="auth__img-container">
                <OneXTwo isHome/>
                <a href="https://crocusoft.com" rel="noreferrer" target="_blank">
                    &copy; {year} Developed by Crocusoft LLC
                </a>
            </div>
            <div className="auth__container">
                <Outlet/>
            </div>
        </div>
    );
}
