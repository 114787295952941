import React, { useState } from 'react';

import RenderIf from 'components/RenderIf';
import { Helmet } from 'react-helmet-async';

import CheckBilet from './CheckBilet';
import CirculationInfo from "./CirculationInfo";
import Table from './Table';


import './result.scss';

export default function Results() {
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState({});
  return (
    <React.Fragment>
      <Helmet>
        <title>Nəticələr | 1x2</title>
        <meta name="description" content="Ana səhifə | 1x2" />
      </Helmet>
      <div className="results">
        <div className="results-top">
          <div className=" p-0 container ">
            <CheckBilet />
          </div>
        </div>
        <div className="results-center">
          <div className="container  results-center__container">
            <div className="results-center__title">
              <span>Nəticələr</span>
            </div>

            <div className="wrapper-table">
              <Table
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                setAllData={setAllData}
              />
            </div>
          </div>
        </div>
        <RenderIf condition={!isLoading}>
          <div className="container p-0 pb-4 overflow-x-auto">
            <CirculationInfo data={allData}/>
          </div>
        </RenderIf>
      </div>
    </React.Fragment>
  );
}
