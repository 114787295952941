import React from "react";

import Accordion from "components/accordion";

import "./FAQ.css";

const FAQ = () => {
    return (
        <div className="faq">
            <div className="container">
                <p className="faq__title">
                    Tez-tez verilən suallar
                </p>
                <Accordion/>
            </div>
        </div>
    )
}

export default FAQ